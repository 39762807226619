import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
function Partnership() {
  return (
    <Box
      m={{
        base: '1rem 0',
        md: '4rem 0',
        lg: '4rem 0',
      }}
      p={{
        base: '2rem 0 ', lg: '2rem 0 4rem',
      }}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Fade direction="up">
          <Text
            as="h3"
            fontWeight="bold"
            fontSize="3xl"
            color="#FEFEFE"
            textAlign="center"
            m={{
              base: '3rem 9.5rem 0.5rem',
              md: '3rem 2rem 0.5rem',
              lg: '3rem 2rem 0.5rem',
            }}
            display="flex"
            justifyContent="center"
          >
            Partnership Approach
          </Text>
        </Fade>

        <Box width="100%" display="flex" justifyContent="center" m="2rem 0 ">
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={3}>
            <Box m="1rem">
              <Fade direction="left">
                <img
                  src="/img/partner-1.png"
                  alt="parntner art"
                  width="300px"
                />
              </Fade>
            </Box>
            <Box m="1rem">
              <Fade direction="up">
                <img
                  src="/img/partner-2.png"
                  alt="parntner art"
                  width="300px"
                />
              </Fade>
            </Box>
            <Box m="1rem">
              <Fade direction="right">
                {' '}
                <img
                  src="/img/partner-3.png"
                  alt="parntner art"
                  width="300px"
                />
              </Fade>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}

export default Partnership;
