import {
  Box,
  Text,
  SimpleGrid,
  Grid,
  GridItem,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react';
import React from 'react';

function ContactCard() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      p={{ base: '1rem 0', lg: '4rem 0' }}
    >
      <Box
        width={{
          base: '100%',
          md: '80%',
          lg: '70%',
        }}
        border="1px solid rgba(255,255,255,0.2)"
        borderRadius="8px"
        background="#272727"
        m={{
          base: '2rem 0.5rem 4rem',
          md: '2rem 2rem 4rem',
          lg: '2rem 2rem 4rem',
        }}
      >
        <Box>
          <Text
            as="h3"
            fontWeight="bold"
            fontSize="2xl"
            color="#FEFEFE"
            textAlign="center"
            m={{
              base: '1rem 0.5rem 0.5rem',
              md: '3rem 2rem 0.5rem',
              lg: '3rem 2rem 0.5rem',
            }}
            display="flex"
            justifyContent="center"
          >
            Ready to unlock the full potential of AI in healthcare?
          </Text>{' '}
          <Text
            as="h3"
            fontWeight="normal"
            fontSize="md"
            color="#FEFEFE"
            textAlign="center"
            m="0.5rem 2rem 0.5rem"
            display="flex"
            justifyContent="center"
          >
            Contact us today to schedule a consultation and discover how Health
            AI Partners can help you navigate the future of healthcare with
            confidence. Together, let's shape a healthier tomorrow through
            innovative AI strategies.
          </Text>
        </Box>
        <Text
          as="h3"
          fontWeight="bold"
          fontSize="3xl"
          color="#FEFEFE"
          textAlign="start"
          m="2rem 2rem 0rem"
          display={{
            base: 'flex',
            md: 'none',
            lg: 'none',
          }}
        >
          Contact Us{' '}
        </Text>
        <Box display="flex" width="100%" justifyContent="center">
          <Box
            width="40%"
            display={{
              base: 'none',
              md: 'flex',
              lg: 'flex',
            }}
            justifyContent="space-evenly"
            flexDirection="column"
            marginLeft="2rem"
          >
            <Text
              as="h3"
              fontWeight="bold"
              fontSize="3xl"
              color="#FEFEFE"
              textAlign="start"
              m="0rem 2rem 0.5rem"
            >
              Contact Us{' '}
            </Text>
            <Box>
              <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                color="#FEFEFE"
                textAlign="start"
                m="2rem 2rem 0.5rem"
              >
                Social Media:
              </Text>
              <Box display="flex" margin="1rem 2rem">
                <img
                  src="/img/fb.png"
                  alt="fb-logo"
                  style={{ margin: '0 0.5rem 0 0' }}
                />
                <img
                  src="/img/instagram.png"
                  alt="fb-logo"
                  style={{ margin: '0 0.5rem' }}
                />{' '}
                <img
                  src="/img/linkedIn.png"
                  alt="fb-logo"
                  style={{ margin: '0 0.5rem' }}
                />{' '}
                <img
                  src="/img/twitter.png"
                  alt="fb-logo"
                  style={{ margin: '0 0.5rem' }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            width={{
              base: '100%',
              md: '60%',
              lg: '60%',
            }}
            margin={{
              base: '0.5rem 1rem',
              md: '2rem',
              lg: '2rem',
            }}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
              <Box m="0">
                <Input
                  placeholder="First Name"
                  my="0.5rem"
                  border="1px solid rgba(250,250,250,0.2)"
                  background="#1A1A1A"
                />
              </Box>
              <Box m="0">
                <Input
                  placeholder="Last Name"
                  my="0.5rem"
                  border="1px solid rgba(250,250,250,0.2)"
                  background="#1A1A1A"
                />
              </Box>
            </SimpleGrid>
            <Input
              placeholder="Email Address"
              my="0.5rem"
              border="1px solid rgba(250,250,250,0.2)"
              background="#1A1A1A"
            />
            <Textarea
              placeholder="Add Message"
              my="0.5rem"
              border="1px solid rgba(250,250,250,0.2)"
              background="#1A1A1A"
            />
            <Button
              w="100%"
              margin="1rem 0 1rem"
              color="#fefefe"
              background="radial-gradient(81.96% 79.37% at 81.96% 19.66%, #0B57A9 0%, #064A93 17.5%, #032951 100%)"
              _hover={{
                background:
                  'radial-gradient(81.96% 79.37% at 81.96% 19.66%, #0B57A9 0%, #064A93 17.5%, #032951 100%)',
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Box
          margin="1rem 2rem 2rem"
          display={{
            base: 'flex',
            md: 'none',
            lg: 'none',
          }}
          justifyContent="center"
        >
          <img
            src="/img/fb.png"
            alt="fb-logo"
            style={{ margin: '0 0.5rem 0 0' }}
          />
          <img
            src="/img/instagram.png"
            alt="fb-logo"
            style={{ margin: '0 0.5rem' }}
          />{' '}
          <img
            src="/img/linkedIn.png"
            alt="fb-logo"
            style={{ margin: '0 0.5rem' }}
          />{' '}
          <img
            src="/img/twitter.png"
            alt="fb-logo"
            style={{ margin: '0 0.5rem' }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ContactCard;
