import React, { useState } from 'react';
import { SimpleGrid, Box, Text } from '@chakra-ui/react';
import { Fade } from 'react-awesome-reveal';
import $ from 'jquery';
import { ImageBannerAnimation } from './animation/ImageAnimation';

function HeroBlock({
  isFixed,
  zoomScale,
  setZoomScale,
  scale,
  opacity,
  scale2,
}) {
  return (
    <Box>
      <Box display={{ base: 'block', md: 'block', lg: 'none' }}>
        <Box width="100%" margin={{ base: '0 1rem', md: '2rem', lg: '3rem' }}>
          <img
            src="/img/hero-art.png"
            alt="hero art"
            width={`${opacity * 100}%`}
            style={{ margin: '6rem auto 3rem', position: 'relative' }}
          />
        </Box>
        <Box justifyContent={{ base: 'start', md: 'start', lg: 'end' }}>
          {/* <Fade direction="up"> */}
          <Text
            as="h1"
            fontWeight="bold"
            fontSize={{ base: '3xl', md: '3xl', lg: '5xl' }}
            color="#FEFEFE"
            textAlign="left"
            width="fit-content"
            position="relative"
            m={{ base: '2rem 1rem', md: '4rem 2rem', lg: '4rem 2rem' }}
          >
            Transforming healthcare by Investing, co-creating and scaling{' '}
            <span style={{ color: '#064A93' }}>cutting-edge AI</span> <br />
            solutions for a healthier tomorrow
          </Text>
          {/* </Fade> */}
        </Box>{' '}
      </Box>
      <Box display={{ base: 'none', md: 'none', lg: 'block' }}>
        <Box
          position={isFixed ? 'fixed' : 'absolute'}
          bottom={isFixed ? '0px' : '80px'}
          height="100vh"
          // transition="bottom 1s ease-in-out"
          width="100%"
        >
          <Text
            className={scale * 31 > 65 ? 'zoomIn' : 'zoomOut'}
            as="h1"
            fontWeight="bold"
            fontSize={{ base: '3xl', md: '3xl', lg: '2.5rem' }}
            color="#FEFEFE"
            textAlign="left"
            position="absolute"
            top="25%"
            left="10%"
            transform="translate(-56%, -50%)"
            justifyContent="center"
          >
            Transforming
            <br /> healthcare by Investing,
            <br /> co-creating and scaling
            <br />
            <span style={{ color: '#064A93' }}>cutting-edge AI</span> <br />
            solutions for a<br /> healthier tomorrow
          </Text>
          <Box
            position="absolute"
            display="flex"
            top="50%"
            left={scale * 31 > 70 ? '70%' : `${scale * 31}%`}
            transform="translate(-56%, -50%)"
            justifyContent="center"
          >
            <Box
              className="zoom"
              opacity={opacity}
              transform={
                scale * 0.6 > 1.2 ? 'scale(1.2)' : `scale(${scale * 0.6})`
              }
              width={{ base: '300px', md: '400px', lg: '400px' }}
              margin={{ base: '0', md: '2rem', lg: ' 2rem' }}
              display={{
                base: 'none',
                md: 'flex',
                lg: 'flex',
              }}
              position="relative"
            >
              <img src="/img/hero-art.png" alt="hero art" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroBlock;
