import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

function AboutBlock() {
  return (
    <Box p="3rem 0" position="relative">
      <Box
        width={{ base: '100%', md: '90%', xl: '80%' }}
        display="flex"
        margin="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Fade direction="up">
          <Text
            as="h3"
            fontWeight="bold"
            fontSize={{ base: '3xl', md: '3xl', lg: '5xl' }}
            color="#FEFEFE"
            textAlign="center"
            m={{
              base: '3rem 0.5rem 0.5rem',
              md: '1rem 0.5rem 0.5rem',
              lg: '1rem 0.5rem 0.5rem',
            }}
            display="flex"
            justifyContent="center"
          >
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '1rem 2rem 0.5rem',
              }}
            ></Box>
            What do we do{' '}
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '1rem 2rem 0.5rem',
              }}
            ></Box>
          </Text>
        </Fade>
        <Text
          as="p"
          fontWeight="normal"
          fontSize="xl"
          color="#FEFEFE"
          textAlign="center"
          m={{ base: '2rem 0.5rem', md: '2rem', lg: '2rem' }}
        >
          <Fade direction="up">
            HealthAI Partners collaborates with innovation-minded healthcare
            providers, payers and stakeholders to achieve maximum value from AI
            applications to healthcare. We strategically invest and partner in
            projects and joint ventures of mutual interest, where we can make an
            impact.
          </Fade>
          <br />
          <br />
          <Fade direction="up">
            Our experienced global AI product development and technology team
            creates AI solutions specifically for healthcare, optimizing
            existing workflows and creating new models of care and operations.
            Our services span the entire spectrum of AI strategy and
            implementation.
          </Fade>
          <br />
          <br />
          <Fade direction="up">
            For use cases ranging from enhancing clinical decision-making to
            streamlining operations to unlocking new avenues for care
            innovation, research and development, we have the expertise to
            partner with you every step of the way and co-create AI solutions
            for a healthier tomorrow.
          </Fade>
        </Text>
      </Box>
    </Box>
  );
}

export default AboutBlock;
