import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { extendTheme } from '@chakra-ui/react';
import '@fontsource/lato';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Navbar from './components/Navbar';
import HeroBlock from './components/Home/HeroBlock';
import TestimonialBlock from './components/Home/TestimonialBlock';
import AboutBlock from './components/Home/AboutBlock';
import Partnership from './components/Home/PartnershipBlock';
import ChooseUsBlock from './components/Home/ChooseUsBlock';
import DesignProcessBlock from './components/Home/DesignProcessBlock';
import ContactCard from './components/ContactCard';
import Footer from './components/Footer';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import DesignProcessBlock1 from './components/Home/DesignProcessBlock1';
import Test1 from './components/Home/Test1';
import { Fade } from 'react-awesome-reveal';

const theme = extendTheme({
  fonts: {
    heading: `'Lato'`,
    body: `'Lato', `,
  },
});

function App() {
  const elemRef = useRef(null);
  const [zoomScale, setZoomScale] = useState(1.8);

  const [isFixed, setIsFixed] = useState(true);
  const [scale, setScale] = useState(1.7);
  const [scale2, setScale2] = useState(0.8);
  const [opacity, setOpacity] = useState(0.7);
  const [opacity2, setOpacity2] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsFixed(scrollPosition < 1000);

      // Scale calculation
      const maxScale = 2.5;
      const minScale = 1.7;
      const scaleFactor = (maxScale - minScale) / 1000; // Adjust this value as needed
      const newScale = Math.min(
        maxScale,
        Math.max(minScale, minScale + scaleFactor * scrollPosition)
      );
      setScale(newScale);

      if (scrollPosition < 300) {
        // Scale calculation for scale2
        const maxScale2 = 1;
        const minScale2 = 0.8;
        const scaleFactor2 = (maxScale2 - minScale2) / 1000; // Adjust this value as needed
        const newScale2 = Math.min(
          maxScale2,
          Math.max(minScale2, minScale2 + scaleFactor2 * scrollPosition)
        );
        setScale2(newScale2);
      }

      // Opacity calculation
      const maxOpacity = 1;
      const minOpacity = 0.7;
      const opacityFactor = (maxOpacity - minOpacity) / 1000; // Adjust this value as needed
      const newOpacity = Math.min(
        maxOpacity,
        Math.max(minOpacity, minOpacity + opacityFactor * scrollPosition)
      );
      setOpacity(newOpacity);

      // Opacity calculation for opacity2
      const maxOpacity2 = 1;
      const minOpacity2 = 0.5;
      const opacityFactor2 = (maxOpacity2 - minOpacity2) / 1000; // Adjust this value as needed
      const newOpacity2 = Math.min(
        maxOpacity2,
        Math.max(minOpacity2, maxOpacity2 - opacityFactor2 * scrollPosition)
      );
      setOpacity2(newOpacity2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ParallaxProvider>
        <Box
          textAlign="center"
          fontSize="xl"
          background="#1A1A1A"
          fontFamily="Lato"
        >
          <Box
            minH="100vh"
            minW="100vw"
            p={0}
            position="relative"
            overflow="hidden"
          >
            {/* Desktop */}
            <Box
              display={{ base: 'none', md: 'none', lg: 'block' }}
              backgroundSize="cover"
              backgroundAttachment="fixed"
              minH="100vh"
              height="250vh"
              position="relative"
              overflow="hidden"
            >
              <img
                src="/img/hero-back.jpg"
                style={{
                  position: 'absolute',
                  top: '0',
                  width: '100%',
                  height: '250vh',
                }}
              />
              <Box display={{ base: 'none', md: 'none', lg: 'flex' }}>
                {isFixed && (
                  <img
                    src="/img/Lines.png"
                    alt="lines"
                    style={{
                      position: 'fixed',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      zIndex: '200',
                      opacity: { opacity2 },
                      transform: `scale(${scale / 1.2})`,
                      overflow: 'hidden',
                    }}
                  />
                )}
              </Box>
              <Navbar isFixed={isFixed} />
              <HeroBlock
                isFixed={isFixed}
                zoomScale={zoomScale}
                setZoomScale={setZoomScale}
                scale={scale}
                opacity={opacity}
                scale2={scale2}
              />
              {
                <ul class="scroll-wrap">
                  <li></li>
                </ul>
              }
            </Box>
            {/* Mobile */}
            <Box display={{ base: 'block', md: 'block', lg: 'none' }}>
              <img
                src="/img/hero-back.jpg"
                style={{
                  position: 'absolute',
                  top: '0', 
                  width: '100%',
                  height: '110vh',
                }}
              />
              {isFixed && (
                <img
                  src="/img/Lines.png"
                  alt="lines"
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: '200',
                    opacity: { opacity2 },
                    transform: `scale(${scale / 1})`,
                    overflow: 'hidden', 
                  }}
                />
              )}
              {scale <= 1.7 && <Navbar isFixed={isFixed} />}
              <HeroBlock
                isFixed={isFixed}
                zoomScale={zoomScale}
                setZoomScale={setZoomScale}
                scale={scale}
                opacity={opacity}
                scale2={scale2}
              />
            </Box>
            {/* <TestimonialBlock /> */}
            {/* <Fade direction="up"> */}
            <AboutBlock /> {/* </Fade> */}
            <Box background="#0B0B0B">
              <Partnership />
            </Box>
            <ChooseUsBlock />
            <Box background="#0B0B0B">
              <Parallax translateY={[-2, 10]}>
                <Box
                  
                >
                  <DesignProcessBlock />
                </Box>
              </Parallax>
            </Box>
            <ContactCard />
          </Box>{' '}
          <Footer />
        </Box>
      </ParallaxProvider>
    </ChakraProvider>
  );
}

export default App;
 